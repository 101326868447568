<template>
  <b-card>
    <b-form>
      <b-container>
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="10">
            <h1>Set up the Base HTML file :</h1>
          </b-col>
        </b-row>
        <hr />
        <!--
        <b-row class="justify-content-md-center">
          <b-col md="10" cols="12">
            <label for="textarea-default">Script :</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Past your script here..."
              rows="12"
              v-model="txtAreaContent"
            />
          </b-col>
        </b-row>
        -->
        <b-row class="justify-content-end mb-2">
          <b-col cols="2">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="dark"
              class="btn-icon rounded-circle"
              v-b-tooltip.hover
              title="Copy"
              v-clipboard:copy="txtAreaContent"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <feather-icon icon="ClipboardIcon" size="20" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="10" cols="12">
            <!-- class="CodeMirror-focused" -->
            <codemirror
              id="codeMirror"
              v-model="txtAreaContent"
              :options="cmOptions"
            ></codemirror>
          </b-col>
        </b-row>
        <b-row class="mt-2 justify-content-md-center">
          <!-- submit and reset -->
          <!-- <b-col> -->
          <b-col offset-sm="1">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="outline-warning"
              class="mr-1"
              @click="updateTheMainHTML"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </b-card>
</template>

<script>
// language js
import "codemirror/mode/javascript/javascript.js";
// theme css
import "codemirror/theme/dracula.css";
import { required } from "@validations";
import {
  BFormTextarea,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  // BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";

import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    // BFormFile,
    BForm,
    VBTooltip,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      txtAreaContent: null,
      cmOptions: {
        // codemirror options
        tabSize: 4,
        mode: "text/javascript",
        theme: "dracula",
        lineNumbers: true,
        line: true,

        showCursorWhenSelecting: true,
        // more codemirror options, 更多 codemirror 的高级配置...
      },
      mScript: [],
      // validation rules
      required,
    };
  },
  computed: {
    codemirror() {
      return this.$refs.myCm.codemirror;
    },
    mainHtml() {
      return this.$store.state.appsManager.mainHtml;
    },
  },
  mounted() {
    this.appendTheMainHtml();
  },
  created() {},
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Main html copied",
          icon: "ClipboardIcon",
          variant: "dark",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy the main html!",
          icon: "ClipboardIcon",
        },
      });
    },
    appendTheMainHtml() {
      this.$store
        .dispatch("appsManager/getMainHTML")
        .then(({ data }) => {
          this.txtAreaContent = data["main-html"].main_html;
        })
        .catch(console.error);
    },
    async updateTheMainHTML() {
      if (!this.txtAreaContent) {
        this.showToast(
          "warning",
          "AlertOctagonIcon",
          "Alert",
          `You need to fill a field so as updating it.`
        );
        return;
      }
      await axios
        .post("/update-main-html", {
          id: this.mainHtml.id,
          main_html: this.txtAreaContent,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.appendTheMainHtml();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
  },
};
</script>
